.module {
  composes: p-2 from global;
  border-top: 1px solid #f2f2f2;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.14);
}

.row {
  composes: flex from global;
  padding: 5px 10px;
}

.text {
  font-size: 15px;
  color: #68717a;
}

.label {
  composes: text;
  composes: flex-auto from global;
}

.bold-label {
  composes: font-bold text-black flex-auto from global;
}

.value {
  composes: text;
  color: var(--c-black-100);
}

.free {
  color: #009b3b;
}

.total {
  font-weight: bold;
  color: var(--c-black-100);
}

.buttons {
  composes: flex mt-2 from global;
  flex-direction: column;
}

.button {
  width: 100%;
}
